<template>
    <div class="data-page">
        <div class="data-content">
            <div class="header">
            </div>
            <div class="content">
                <div class="inner">
                    im
                    <div class="msg-list"></div>
                    <div class="send">
                        发送
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'MashPushData',
    data() {
        return {
            room_info: {},
        };
    },
    components: {
    },
    async created() {
        this.initData();
    },
    mounted() {

    },
    methods: {
        //初始化 
        initData(){},
    },
    beforeDestroy() {
    },
    
};
</script>

<style lang="scss" scoped>
.data-page {
    height: 100vh !important;
    overflow: hidden !important;
    background-color: #2a2c37;
}

.inner {
    width: 1600px !important;
    max-width: none !important;
    margin: auto;
}

.send {
    padding: 10px 20px;
    color: #fff;
    border-radius: 3px;
    background-color: #246CF9;
    cursor: pointer;
}
.data-page .header {
    height: 46px;
    background-image: linear-gradient(90deg, rgb(49, 51, 65) 0%, rgb(42, 44, 55) 100%);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
    border-top: 3px solid #c7a37a;
    position: relative;
    margin-bottom: 60px;
}

.data-page .header:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0;
    right: 0;
    background-image: url('https://resources.oz4.cn/mashang/push_img/header_line_bg.png');
    background-repeat: repeat-x;
    background-position: right bottom;
}

.data-page .header .inner {
    position: relative;
}

.data-page .header .inner .nav {
    position: absolute;
    left: 0;
    display: flex;
}

.data-page .header .inner .nav .item {
    color: #666985;
    height: 50px;
    line-height: 50px;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.data-page .header .inner .nav .item:hover {
    color: #50A0FF;
    transition: 0.3s;
}

.data-page .header .inner .brand {
    width: 660px;
    height: 80px;
    margin: auto;
    background-image: url('https://resources.oz4.cn/mashang/data/brand_bg.png');
    background-repeat: no-repeat;
    margin-top: -3px;
    display: flex;
}

.data-page .header .inner .brand .logo {
    width: 50px;
    height: 50px;
    margin-left: 50px;
    margin-top: 12px;
    margin-right: 15px;
}

.data-page .header .inner .brand .logo .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.data-page .header .inner .brand .brand-name {
    line-height: 75px;
    height: 75px;
    color: #2a2c37;
    font-size: 26px;
}


.data-page .data-content .right {
    width: 380px;
    margin-left: 20px;
}


.data-page .data-content .content .inner {
    display: flex;
}

.data-page .data-content .content .left {
    width: 380px;
    margin-right: 20px;
}

.data-page .data-content .content .core-data-box {
    width: 800px;
    height: 380px;
    background-color: #2a2c37;
    background-image: linear-gradient(130deg, #50536d 0%, #282e46 100%);
    box-shadow: -5px 0px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 9px;
}

.data-page .data-content .content .core-data-box .title {
    height: 60px;
    line-height: 60px;
}

.data-page .data-content .content .core-data-box .title .name {
    padding-left: 20px;
    font-size: 16px;
    color: #9199bf;
    margin-right: 10px;
    font-weight: bold;
}

.data-page .data-content .content .core-data-box .title .info {
    font-size: 12px;
    color: #515a6e;
}

.data-page .data-content .content .core-data-box .con {
    padding: 30px;

}

.core-data-box .con .big-list {
    display: flex;
    margin-bottom: 40px;
    margin-top: 10px;
}

.core-data-box .con .small-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.core-data-box .con .core-data-item {
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.core-data-box .con .core-data-item .num {
    color: #fff;
    font-weight: bold;
}

.core-data-box .con .big-list .core-data-item {
    width: 33.3%;
    text-align: center;
}

.core-data-box .con .big-list .core-data-item .num {
    font-size: 32px;
    line-height: 32px;
    color: #adc1ff;
}

.core-data-box .con .big-list .core-data-item .desc {
    color: #6c7183;
}

.core-data-box .con .small-list .core-data-item {
    width: 20%;
    margin-bottom: 30px;
    text-indent: 30px;
}

.core-data-box .con .small-list .core-data-item .num {
    font-size: 22px;
    line-height: 22px;
    color: #adc1ff;
}

.core-data-box .con .small-list .core-data-item .desc {
    color: #6c7183;
}


.room-page .play {
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
    background-color: rgba(0, 0, 0, .4);
    border-radius: 6px;
    overflow: hidden;
}

.room-page .info {
    margin-left: 20px;
    width: 460px;
    min-width: 460px;
    min-height: 810px;
    background-color: rgba(0, 0, 0, .4);
    position: relative;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
    border-radius: 6rpx;
}

.room-page .info .title {
    font-size: 16px;
    line-height: 40px;
    color: #ddd;
    height: 40px;
    margin: 10px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}



.room-page .info .msg-bar {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
}


/* 基础卡片 */
.card {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    background-color: #36384a;
    border-radius: 6px;
}

.card .title {
    height: 50px;
    line-height: 50px;
}

.card .title .name {
    padding-left: 20px;
    font-size: 16px;
    color: #666985;
    margin-right: 10px;
    font-weight: normal;
}

.card .con {
    width: 100%;
    height: 100%;
    display: flex;

}

#online-order-chart {
    width: 100%;
    height: 100%;
}

#order-proportion-chat {
    width: 100%;
    height: 100%;
}

.goods-rank {
    height: 455px;
    margin-top: 20px;
}

.goods-rank .goods-list {
    padding: 10px 15px;
    height: 415px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.goods-rank .goods-item {
    display: flex;
    margin-bottom: 5px;
    background-color: #252834;
    padding: 10px;
    border-radius: 3px;
    color: #666985;
}

.goods-rank .goods-item .goods-name {
    color: #666985;
    width: 200px;
    
}

.goods-rank .goods-item .goods-name .index {
    background-color: #246CF9;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
}


.goods-rank .goods-item .goods-cover {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.goods-rank .goods-item .goods-cover .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

.goods-rank .goods-item .goods-info {
    width: 130px;
}

.goods-rank .goods-item .goods-info .goods-name {}

.goods-rank .goods-item .statis {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.goods-rank .goods-item .statis .num font {
    color: #2EE491;
    margin-right: 5px;
}

.goods-rank .goods-item .statis .money font {
    color: #246CF9;
    margin-right: 5px;
}


.invite {
    margin-top: 15px;
    height: 330px;
}

.invite .invite-rank {
    width: 100%;
}

.invite .invite-rank .index {
    background-color: #2EE491;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.consume {
    margin-top: 15px;
    height: 330px;
}

.consume .consume-rank {
    width: 100%;
}



.consume .consume-rank .index {
    background-color: #246CF9;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #fff;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.rank-list {
    padding: 0 15px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.rank-list .item {
    height: 36px;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 6px;
    line-height: 36px;
    box-sizing: content-box;
}

.rank-list .item .avatar {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 15px;
}

.rank-list .item .name {
    display: inline-block;
    color: #fff;
    vertical-align: top;
}

.rank-list .item .num {
    color: #fff;
    float: right;
}

.center-main {
    display: flex;
    margin-top: 15px;
}

.center-side-left {
    width: 320px;
    height: 495px;
    margin-right: 15px;
}

.center-side-left #rate {
    width: 100%;
    height: 100%;
}

.center-side-right {
    width: 465px;
    height: 495px;
}

.share {
    height: 140px;
}

.share .con {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.share .data-item {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .1);
    width: 100px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
}

.share .data-item .num {
    font-size: 16px;
    line-height: 32px;
    color: #adc1ff;
}

.share .data-item .desc {
    color: #6c7183;
}

.order {
    margin-top: 15px;
    height: 340px;
}

.order .con {
    display: block;
    padding: 10px 15px;
    height: 415px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;
}

.order .order-no-pay {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .1);
    width: 100px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
}


.order .order-no-pay .num {
    font-size: 16px;
    line-height: 32px;
    color: #adc1ff;
}

.order .order-no-pay .desc {
    color: #6c7183;
}

.order .order-list  .order-item{
    display: flex;
    margin-bottom: 5px;
    background-color: #252834;
    padding: 10px;
    border-radius: 3px;
    color: #666985;
    position: relative;
}

.order .order-list  .order-item .goods-cover {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.order .order-list  .order-item .goods-cover .image {
    width: 100%;
    height: 100%;
}

.order .order-list  .order-item  .statis {
    position: absolute;
    bottom: 5px;
    right: 10px;
}
.order .order-list  .order-item  .statis font {
    margin-right: 5px;
}
.qrcode {
    margin-top: 15px;
}
</style>